import { PRODUCTS } from '../actions/types'

const initialState = {
    listado: [
        {
            nombre: 'Block 12 x 20 x 40', 
            detalles: '', 
            imagenes: ['/assets/img/productos/block/12x20x40_1.png'], 
            resistencia: ['60 kg/cm²', '90 kg/cm²'], 
            anchura: '12 cm', 
            altura: '20 cm', 
            largo: '40 cm', 
            precio: '$ 6.00'
        }, 
        {
            nombre: 'Bovedilla', 
            detalles: '', 
            imagenes: ['/assets/img/productos/bovedilla/bovedilla_1.png'], 
            resistencia: ['60 kg/cm²'], 
            anchura: null, 
            altura: null, 
            largo: null, 
            precio: '$ 6.00'
        }, 
        {
            nombre: 'Block 15 x 20 x 40', 
            detalles: '', 
            imagenes: ['/assets/img/productos/block/15X20X40_1.png'], 
            resistencia: ['60 kg/cm²', '90 kg/cm²'], 
            anchura: '15 cm', 
            altura: '20 cm', 
            largo: '40 cm', 
            precio: '$ 6.00'
        }, 
        {
            nombre: 'Tabicón 14 x 10 x 28', 
            detalles: '', 
            imagenes: ['/assets/img/productos/tabicon/tabicon_1.png'], 
            resistencia: [], 
            anchura: '10 cm', 
            altura: '14 cm', 
            largo: '28 cm', 
            precio: '$ 6.00'
        }, 
        {
            nombre: 'Block 20 x 20 x 40', 
            detalles: '', 
            imagenes: ['/assets/img/productos/block/20x20x40_1.png'], 
            resistencia: ['60 kg/cm²', '90 kg/cm²'], 
            anchura: '20 cm', 
            altura: '20 cm', 
            largo: '40 cm', 
            precio: '$ 6.00'
        }, 
        {
            nombre: 'Block cara de piedra 20 x 20 x 40', 
            detalles: '', 
            imagenes: ['/assets/img/productos/block/20x20x40_cara_piedra_1.png'], 
            resistencia: ['60 kg/cm²'], 
            anchura: '20 cm', 
            altura: '20 cm', 
            largo: '40 cm', 
            precio: '$ 6.00'
        }, 
        {
            nombre: 'Mitad 12', 
            detalles: '', 
            imagenes: ['/assets/img/productos/block/mitad_12_1.png'], 
            resistencia: ['60 kg/cm²', '90 kg/cm²'], 
            anchura: '12 cm', 
            altura: '20 cm', 
            largo: '20 cm', 
            precio: '$ 6.00'
        }
    ], 
    detalles: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case PRODUCTS.DETALLES:
            return {
                ...state,
                detalles: {
                        id: payload, 
                    ...state.listado[payload]
                }
            };
            
        default:
            return state;
    }
}