import React, { useEffect, Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Nosotros = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <section className="about" data-aos="fade-up">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                                <Carousel
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    interval={5000}
                                >
                                    <div>
                                        <img src="assets/img/nosotros/camiones_1.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="assets/img/nosotros/maquinaria_1.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="assets/img/nosotros/almacen_1.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="assets/img/nosotros/transporte.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="assets/img/nosotros/maquinaria_2.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="assets/img/nosotros/planta_arellano.png" alt="" />
                                    </div>
                            </Carousel>
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0">
                            <h3>¿Quienes somos?</h3>
                            <p className="text-justify">
                                <b>IMHABLOCK</b> es una empresa dedicada a la elaboración, venta y distribución de materiales vibrocomprimidos para la construcción. La empresa inicia en 2014 en la ciudad de Aguascalientes por la necesidad de ofrecer un producto de calidad dentro del mercado, que cumpla con las necesidades del ritmo de la construcción actual esto con la finalidad de garantizar la durabilidad y plusvalía de su inversión.
                            </p>
                            <ul>
                                <li><i className="bi bi-check2-circle"></i> Garantia de satisfacción.</li>
                                <li><i className="bi bi-check2-circle"></i> Empleamos la mejor calidad de agregados para la elaboración de nuestro producto.</li>
                                <li><i className="bi bi-check2-circle"></i> Nuestros productos son sometidos a rigurosos estándares de calidad.</li>
                                <li><i className="bi bi-check2-circle"></i> Gran cantidad de modelos para un diseño variado.</li>
                                <li><i className="bi bi-check2-circle"></i> Rápido tiempo de entrega.</li>
                                <li><i className="bi bi-check2-circle"></i> Cumplimos con las normas oficiales mexicana de la construcción.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
                
            <section className="why-us section-bg" data-aos="fade-up" date-aos-delay="200">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-6 video-box">
                            <img src="assets/img/nosotros/video_cover.png" className="img-fluid" alt="" />
                            <a href="assets/video/presentacion.mp4" className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a>
                        </div>

                        <div className="col-lg-6 d-flex flex-column justify-content-center p-5">

                            <div className="icon-box">
                                <div className="icon"><i className="bx bx-fingerprint"></i></div>
                                <h4 className="title"><a href="">Misión</a></h4>
                                <p className="description">Llegar a ser una empresa líder en la fabricación y distribución de productos vibrocomprimidos y de concreto en la región. Ofreciendo siempre el mejor servicio y calidad a nuestros clientes.</p>
                            </div>

                            <div className="icon-box">
                                <div className="icon"><i className="bx bx-gift"></i></div>
                                <h4 className="title"><a href="">Visión</a></h4>
                                <p className="description">Proporcionar a nuestros clientes productos de calidad que contribuyan al desarrollo y crecimiento de la región. Cumpliendo los estándares de calidad y fomentando el aumento en la capacidad integra de nuestro personal y empresa.</p>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
	    </Fragment>
    )
}

export default Nosotros;